import React from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography } from '@alphakits/ui';
import { PaymentMethodEnum } from '@escapenavigator/types/dist/openapi/orders/enum/payment-type.enum';
import classNames from 'classnames';
import { TFunction } from 'i18next';

import styles from './index.module.css';

const IMAGES = {
    cash: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget/cash.png',
    stripe: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget/online.png',
    paypal: 'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget/paypal.png',
};

type Props = {
    onClick: () => void;
    loading?: boolean;
    selected: boolean;
    type: PaymentMethodEnum;
};

export const getPaymentMethodTitle = (type: PaymentMethodEnum, t: TFunction) => {
    const titles: Record<'cash' | 'stripe' | 'paypal', string> = {
        cash: t('paymentMethods.cash'),
        stripe: t('paymentMethods.stripe'),
        paypal: t('paymentMethods.paypal'),
    };

    return titles[type];
};

export const PaymentMethodCell: React.FC<Props> = ({
    onClick, selected, loading, type,
}) => {
    const { t } = useTranslation();

    const title = getPaymentMethodTitle(type, t);

    return (
        <Skeleton visible={ loading }>
            <button
                type="button"
                className={ classNames(styles.btn, { [styles.selected]: selected }) }
                onClick={ onClick }
            >
                <img alt={ type } src={ IMAGES[type] } />

                <Typography.Text view="title" tag="div" color="primary">
                    { title }
                </Typography.Text>
            </button>
        </Skeleton>
    );
};
